import { defineStore } from "pinia"
import { useNuxtApp } from "#app"
import moment from "moment-timezone"
import {
  TimelineGroupId,
  TimelineGroup,
  Comment,
  CommentCreationRequestPayload,
  AnalyticsEvent,
  TimelineCommentsUrlParams,
} from "@evercam/shared/types"
import {
  TimelineColors,
  TimelineSharedConfig,
} from "@evercam/shared/constants/timeline"
import { isDateWithinRange } from "@evercam/shared/utils"
import { useTimelineStore } from "@/stores/timeline/timeline"
import { useLayoutStore } from "@/stores/layout"
import { useAccountStore } from "@/stores/account"
import { useRecordingsStore } from "@/stores/recordings"
import { useTimelineBimGroupStore } from "@/stores/timeline/timelineBimGroup"
import { useCommentStore } from "@/stores/comment"

const groupId = TimelineGroupId.Comments

interface TimelineCommentsGroupState {
  isAnnotationActive: boolean
  showComments: boolean
  visibleComments: Comment[]
  selectedComment: Comment
}

export const useTimelineCommentsGroupStore = defineStore({
  id: "timelineCommentsGroup",
  state: (): TimelineCommentsGroupState => ({
    isAnnotationActive: false,
    showComments: false,
    visibleComments: [] as Comment[],
    selectedComment: null as Comment,
  }),
  getters: {
    hasComments(): boolean {
      return (
        useCommentStore().comments.length > 0 ||
        useCommentStore().archivedComments.length > 0
      )
    },
    hasActiveComments(): boolean {
      return useCommentStore().comments.length > 0
    },
    isDisabled(): boolean {
      return !this.hasComments
    },
    isVisible(state): boolean {
      return state.showComments && !this.isDisabled
    },
    group(): TimelineGroup {
      const comments = useCommentStore().comments?.map((d) => {
        return {
          ...d,
          milestoneType: TimelineGroupId.Comments,
          color: TimelineColors.comments,
          timestamp: d.timestamp,
          text: d.content,
        }
      })

      return {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
        timelineConfig: {
          ...TimelineSharedConfig,
          label: useNuxtApp()
            .vue2App.$i18n.t(`content.etimeline.labels.${groupId}`)
            .toString(),
          color: TimelineColors.comments,
          dotsSize: 1,
          events: comments,
          height: 40,
        },
      }
    },
  },

  actions: {
    async addComment(project, comment: CommentCreationRequestPayload) {
      const newComment = await useCommentStore().addComment(project, comment)
      await this.filterCommentsByInterval(useTimelineStore().snapshotsInterval)
      this.selectedComment = { ...newComment, canDelete: true }
    },
    async deleteComment(project, commentId) {
      useCommentStore().deleteComment(project, commentId)
    },
    async archiveOrUnarchiveComment(project, commentId, archive) {
      useCommentStore().archiveOrUnarchiveComment(project, commentId, archive)
      this.filterCommentsByInterval(useTimelineStore().snapshotsInterval)
    },
    filterCommentsByInterval(snapshotInterval) {
      this.visibleComments = useCommentStore().comments.filter(
        (comment) =>
          isDateWithinRange(
            comment.timestamp,
            snapshotInterval.from,
            snapshotInterval.to
          ) &&
          (!comment.cameraExid ||
            comment.cameraExid === useTimelineStore().cameraExid)
      )
    },
    selectComment(comment) {
      const targetTime = moment(comment.timestamp).toISOString()
      const canDelete = comment.creatorEmail === useAccountStore().email
      useTimelineStore().selectTimestamp(targetTime)
      if (comment.cameraExid !== useTimelineStore().cameraExid) {
        useTimelineStore().selectCamera(comment.cameraExid)
      }
      this.selectedComment = { ...comment, canDelete }
      useLayoutStore().enableRightSidebar()
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.CommentsSelect
      )
    },
    changeCommentsVisibility(visibilityStatus) {
      if (this.showComments === visibilityStatus) {
        return
      }
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.GroupsVisibilityComments,
        { visible: visibilityStatus }
      )
      this.showComments = visibilityStatus
      if (visibilityStatus) {
        useTimelineStore().isCopilotEnabled = false
        useTimelineBimGroupStore().changeBimeMilestonesVisibility(false)
        useRecordingsStore().isXrayActive = false
      }
    },
    allowComment(isActive) {
      if (this.isAnnotationActive === isActive) {
        return
      }
      this.isAnnotationActive = isActive
      useNuxtApp().nuxt2Context.$analytics.saveEvent(AnalyticsEvent.Comment, {
        active: isActive,
      })
    },
  },
  syncWithUrl: Object.values(
    TimelineCommentsUrlParams
  ) as (keyof TimelineCommentsGroupState)[],
})
